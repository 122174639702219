// Home.js
import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TopItem } from "../components/home/TopItem";
import Pie from "../components/home/Pie";
import Bar from "../components/home/Bar";
import { GasMeter, People, Tapas, WaterDrop } from "@mui/icons-material";
import { useLayoutEffect } from "react";
import { useRef, useState, useEffect } from "react";

function Home(props) {
  const [data, setData] = useState(null);
  const [projects, setProjects] = useState(null);
  const chrt = useRef(null);
  const [showing, setShowing] = useState(true);
  const [aspect, setAspect] = useState(1.5);
  const [aspect1, setAspect1] = useState(1.5);

  const p1ref = useRef();
  const p2ref = useRef();
  const p3ref = useRef();

  const b1ref = useRef();
  const b2ref = useRef();

  const h1ref = useRef();
  const h2ref = useRef();

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  useEffect(() => {
    setShowing(false);
    setTimeout(() => {
      setShowing(true);
    }, 1);
  }, [props.showing]);

  useEffect(() => {
    fetch(`/api/customers/all/stats`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/customers/all/charts`)
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setProjects(data);
      })
      .catch((e) => {});
  }, []);

  return (
    <Box marginTop={8} padding={1}>
      <Grid container spacing={3}>
        <Grid xs={6} sm={3} md={3}>
          <TopItem
            title="Customers"
            value={data ? data?.Customers[0].total : 0}
            icon={<People fontSize="16px" />}
            trend="up"
          />
        </Grid>
        <Grid xs={6} sm={3} md={3}>
          <TopItem
            title="Tanks"
            value={data ? data?.Tanks[0].total : 0}
            trend="up"
            icon={<WaterDrop fontSize="16px" />}
          />
        </Grid>
        <Grid xs={6} sm={3} md={3}>
          <TopItem
            title="Valves"
            value={data ? data?.Valves[0].total : 0}
            trend="up"
            icon={<Tapas fontSize="16px" />}
          />
        </Grid>
        <Grid xs={6} sm={3} md={3}>
          <TopItem
            title="Manholes"
            value={data ? data?.Manholes[0].total : 0}
            trend="up"
            icon={<GasMeter fontSize="16px" />}
          />
        </Grid>
        <Grid md={4} xs={12}>
          <Pie
            title="Account Type"
            data={projects?.AccountType}
            chartSeries={[5, 1]}
            labels={["Support Issues", "Resolved"]}
            sx={{ height: "100%" }}
          />
        </Grid>
        <Grid md={4} xs={12}>
          <Pie
            title="Account Status"
            data={projects?.AccountStatus}
            sx={{ height: "100%" }}
          />
        </Grid>
        <Grid md={4} xs={12}>
          <Pie
            title="Meter Status"
            data={projects?.MeterStatus}
            sx={{ height: "100%" }}
          />
        </Grid>
        <Grid md={6} xs={12}>
          <Bar title="DMA" data={projects?.DMA} sx={{ height: "100%" }} />
        </Grid>
        <Grid md={6} xs={12}>
          <Bar
            title="Meter Class"
            data={projects?.Class}
            sx={{ height: "100%" }}
          />
        </Grid>
        <Grid xs={12}>
          <Bar
            title="Zone"
            chartSeries={[
              {
                name: "This year",
                data: [18, 16, 5, 8, 3, 14, 14, 16, 17, 19, 18, 20],
              },
            ]}
            sx={{ height: "100%" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
