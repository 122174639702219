import React, { useState } from "react";
import { Box, ButtonGroup, Button, Container, Card } from "@mui/material";
import IncidencesNew from "../components/Incidences/IncidencesNew";
import IncidencesAllMAp from "../components/Incidences/IncidencesAllMap";
import DataIncidences from "../components/NRW/DataIncidences";
import IncidentsPage from "../components/Incidences/IncidentsPage";
import Categories from "../components/Categories/Categories";

const selectList = [
  "Statistics",
  "All",
  "Pipe Leaks",
  "Sewer Burst",
  "Illegal Connections",
  "Supply Fail",
  "Vandalism Cases",
  "Other",
  "Reports",
  "Categories",
];

export default function NRW() {
  const [selectedNetwork, setSelectedNetwork] = useState("Statistics");

  const handleNetworkChange = (network) => {
    setSelectedNetwork(network);
  };

  return (
    <Container sx={{ marginTop: 8 }} disableGutters>
      <Card
        sx={{
          p: 2,
          borderRadius: 5,
          boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          minHeight: "90vh",
          display: "grid",
          gridTemplateRows: "auto auto 1fr",
        }}
      >
        <ButtonGroup
          variant="contained"
          sx={{
            boxShadow: "none",
          }}
          aria-label="network toggle buttons"
        >
          {selectList.map((item, i) => {
            return (
              <Button
                key={i}
                onClick={() => handleNetworkChange(item)}
                variant={selectedNetwork === item ? "contained" : "outlined"}
                sx={{ textTransform: "capitalize" }}
              >
                {item}
              </Button>
            );
          })}
        </ButtonGroup>

        <Box>{selectedNetwork == "Statistics" && <IncidencesNew />}</Box>
        <Box>
          {selectedNetwork == "All" && <IncidencesAllMAp url={"all"} />}
        </Box>
        <Box>
          {selectedNetwork == "Pipe Leaks" && <IncidentsPage url="Leakage" />}
        </Box>
        <Box>
          {selectedNetwork == "Sewer Burst" && (
            <IncidentsPage url="Sewer Burst" />
          )}
        </Box>
        <Box>
          {selectedNetwork == "Illegal Connections" && (
            <IncidentsPage url="Illegal Connection" />
          )}
        </Box>
        <Box>
          {selectedNetwork == "Supply Fail" && (
            <IncidentsPage url="Supply Fail" />
          )}
        </Box>
        <Box>
          {selectedNetwork == "Vandalism Cases" && (
            <IncidentsPage url="Vandalism" />
          )}
        </Box>
        <Box>{selectedNetwork == "Other" && <IncidentsPage url="Other" />}</Box>
        <Box>
          {selectedNetwork == "Reports" && (
            <DataIncidences
              url="/reports/paginated"
              title="Incidences Data"
              search="by serial no."
            />
          )}
        </Box>
        <Box>{selectedNetwork == "Categories" && <Categories />}</Box>
      </Card>
    </Container>
  );
}
