import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import "../../Styles/gis.scss";
import Map from "ol/Map";
import { useState, useRef, useEffect } from "react";
import { OSM } from "ol/source";
import { ZoomToExtent, defaults as defaultControls } from "ol/control";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import Overlay from "ol/Overlay";
import GeoJSON from "ol/format/GeoJSON";
import {
  Checkbox,
  Box,
  Typography,
  Card,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

export default function NetworksMaps(props) {
  const [map, setMap] = useState();
  const mapElement = useRef();
  const mapRef = useRef();
  mapRef.current = map;
  const tooltip = useRef();
  const popupRef = useRef();
  const [popupContent, setPopupContent] = useState(null);

  useEffect(() => {
    // create map
    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        projection: "EPSG:4326",
        center: [37.1274, -0.4832],
        zoom: 16,
        maxZoom: 32,
      }),
      controls: defaultControls().extend([
        new ZoomToExtent({
          extent: [
            37.05507278442383, -0.558377325534821, 37.19850540161133,
            -0.367469221353531,
          ],
        }),
      ]),
    });

    const popupOverlay = new Overlay({
      element: popupRef.current,
      positioning: "bottom-center",
      stopEvent: false,
    });
    initialMap.addOverlay(popupOverlay);

    initialMap.on("singleclick", function (event) {
      const features = initialMap.getFeaturesAtPixel(event.pixel);

      if (features.length > 0) {
        const properties = features[0].getProperties();
        delete properties.geometry;
        delete properties.ID;
        delete properties.createdAt;
        delete properties.updatedAt;
        delete properties.User;
        delete properties.Coordinates;

        setPopupContent(properties);
        popupOverlay.setPosition(event.coordinate);
      } else {
        setPopupContent(null);
        popupOverlay.setPosition(undefined);
      }
    });

    setMap(initialMap);
    return () => {
      initialMap.setTarget(null);
    };
  }, []);

  useEffect(() => {
    if (map && props.legendItems.length > 0) {
      props.legendItems.map(async (item) => {
        await fetch(`/api/geojson/${item.table}`)
          .then((res) => {
            if (res.ok) return res.json();
            else throw new Error("Failed to fetch GeoJSON data");
          })
          .then((data) => {
            if (data.features != null) {
              const vectorSource = new VectorSource({
                features: new GeoJSON({
                  dataProjection: "EPSG:4326",
                  featureProjection: map.getView().getProjection().getCode(),
                }).readFeatures(data),
              });

              const style = new Style({
                image: new CircleStyle({
                  stroke: new Stroke({
                    color: item.stroke,
                    width: 1,
                  }),
                  fill: new Fill({
                    color: item.fill,
                  }),
                  radius: item.radius,
                }),
                stroke: new Stroke({
                  color: item.fill,
                  width: 3,
                }),
                fill: new Fill({
                  color: item.fill,
                }),
              });

              const layer = new VectorLayer({
                title: item.label,
                source: vectorSource,
                style: style,
              });

              map.addLayer(layer);
              const extent = vectorSource.getExtent();
              map.getView().fit(extent, { padding: [50, 50, 50, 50] });
            }
          })
          .catch((e) => {
            console.error(e);
          });
      });
    }
  }, [map, props.legendItems]);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        borderRadius: "8px",
      }}
      className="gis"
    >
      <div
        ref={mapElement}
        style={{ width: "100%", height: "100%" }}
        id="map"
      ></div>
      <Card
        sx={{
          position: "absolute",
          bottom: "1rem",
          backgroundColor: "rgba(255,255,255,0.7)",
          left: "1rem",
          py: 1,
          px: 2,
          borderRadius: "8px",
        }}
      >
        <Typography variant="h6">Legend</Typography>
        <Divider sx={{ mb: "10px" }} />
        {props.legendItems.map((item, i) => {
          return <LegendItem key={i} item={item} map={map} />;
        })}
      </Card>
      <Paper
        ref={popupRef}
        sx={{
          position: "absolute",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "8px",
          display: popupContent ? "block" : "none",
          zIndex: 10,
          transform: "translate(-50%, -100%)",
        }}
      >
        <Typography variant="subtitle1">Feature Details</Typography>
        <Divider sx={{ mb: "5px" }} />
        <List
          sx={{
            display: "grid",
            gap: "5px",
            p: 0,
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          {popupContent &&
            Object.keys(popupContent).map((key, index) => (
              <ListItem
                sx={{
                  display: "grid",
                  gap: "5px",
                  minWidth: "250px",
                  gridTemplateColumns: "1fr 2fr",
                }}
                key={index}
                dense
              >
                <Typography sx={{ fontSize: "small", color: "#131842" }}>
                  {key}
                </Typography>
                <Typography sx={{ fontSize: "small", color: "#698474" }}>
                  {popupContent[key]}
                </Typography>
              </ListItem>
            ))}
        </List>
      </Paper>
    </div>
  );
}

const LegendItem = (props) => {
  const [showing, setShowing] = useState(true);

  useEffect(() => {
    if (props.map) {
      props.map.getLayers().forEach((layer) => {
        if (layer && layer.get("title") === props.item.label) {
          layer.setVisible(showing);
        }
      });
    }
  }, [showing, props.map, props.item.label]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mb: "3px",
        gap: 1,
      }}
    >
      <Checkbox
        checked={showing}
        sx={{
          height: 10,
          width: 10,
        }}
        onChange={(e) => setShowing(e.target.checked)}
      />
      <Box
        sx={{
          border: `3px solid ${props.item.stroke}`,
          backgroundColor: props.item.fill,
          height: 16,
          width: 16,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
      <Typography variant="body2">{props.item.label}</Typography>
    </Box>
  );
};
