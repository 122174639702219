import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Chip,
  CircularProgress, // Import CircularProgress for loading spinner
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CreateUser from "../users2/CreateUser";
import MyPagination from "../Pagination";

export default function Data(props) {
  const [selectedNetwork, setSelectedNetwork] = React.useState("Portal Users");
  const [data, setData] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [changed, setChanged] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [loading, setLoading] = React.useState(false); // Add loading state

  React.useEffect(() => {
    fetchData();
  }, [props.url, changed, currentPage]);

  const fetchData = () => {
    setLoading(true); // Set loading to true when fetching starts
    setData(null);

    fetch(`/api/${props.url}/paginated/${(currentPage - 1) * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setData(data);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((err) => {
        console.error(err);
        setLoading(false); // Set loading to false even on error
      });
  };

  const handlePageChange = (newPage) => {
    console.log(newPage);
    setCurrentPage(newPage);
  };

  const renderTableHeaders = () => {
    if (!data || !data.data || data.data.length === 0) return null;

    // Extract column names from the first object in the data array
    let dd = data.data[0];
    delete dd.geom;
    delete dd.ID;
    delete dd.createdAt;
    delete dd.updatedAt;
    delete dd.User;
    delete dd.Coordinates;
    const columnNames = Object.keys(data.data[0]);

    return (
      <>
        {columnNames.map((columnName) => (
          <TableCell key={columnName}>{columnName}</TableCell>
        ))}
      </>
    );
  };

  const renderTableRows = () => {
    if (!data || !data.data) return null;

    return data.data.map((user, index) => {
      delete user.geom;
      delete user.ID;
      delete user.createdAt;
      delete user.updatedAt;
      delete user.User;
      delete user.Coordinates;

      return (
        <TableRow key={user.UserID}>
          <TableCell>{(currentPage - 1) * 12 + index + 1}</TableCell>

          {/* Dynamically render each cell based on the data */}
          {Object.keys(user).map((key) => (
            <TableCell key={key}>{user[key]}</TableCell>
          ))}
        </TableRow>
      );
    });
  };

  return (
    <Box padding={1}>
      <Box
        sx={{
          overflowX: "auto",
          minHeight: "50vh",
          maxWidth: "85vw",
          overflowX: "auto",
        }}
      >
        {loading ? ( // Display loading spinner if data is being fetched
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table sx={{ minWidth: "800px" }}>
            <TableHead>
              <TableRow>
                <TableCell>SN</TableCell>
                {renderTableHeaders()}
              </TableRow>
            </TableHead>
            <TableBody>{renderTableRows()}</TableBody>
          </Table>
        )}
      </Box>
      <Divider />

      <MyPagination
        totalPages={data ? Math.ceil(data.total / 12) : 0}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
      />
    </Box>
  );
}
