import React, { useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Container,
  Divider,
  Card,
} from "@mui/material";
import NetworksMaps from "../components/map/NetworksMap";

export default function MyMap() {
  const [selectedNetwork, setSelectedNetwork] = useState("Water Network");

  const handleNetworkChange = (network) => {
    setSelectedNetwork(network);
  };

  return (
    <Container sx={{ marginTop: 8 }} disableGutters>
      <Card
        sx={{
          p: 2,
          borderRadius: 5,
          boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          height: "110vh",
          display: "grid",
          gridTemplateRows: "auto auto 1fr",
        }}
      >
        <ButtonGroup
          variant="contained"
          sx={{
            boxShadow: "none",
          }}
          aria-label="network toggle buttons"
        >
          <Button
            onClick={() => handleNetworkChange("Water Network")}
            variant={
              selectedNetwork === "Water Network" ? "contained" : "outlined"
            }
            sx={{ textTransform: "capitalize" }}
          >
            Water Network
          </Button>
          <Button
            onClick={() => handleNetworkChange("Sewer Network")}
            variant={
              selectedNetwork === "Sewer Network" ? "contained" : "outlined"
            }
            sx={{ textTransform: "capitalize" }}
          >
            Sewer Network
          </Button>
          <Button
            onClick={() => handleNetworkChange("Project Network")}
            variant={
              selectedNetwork === "Project Network" ? "contained" : "outlined"
            }
            sx={{ textTransform: "capitalize" }}
          >
            Project Network
          </Button>
        </ButtonGroup>
        <Divider sx={{ my: 1 }} />

        <Box sx={{ position: "relative" }}>
          {selectedNetwork === "Water Network" && (
            <NetworksMaps
              legendItems={[
                {
                  label: "Customers Meters",
                  stroke: "#FF8343",
                  fill: "#FFAF00",
                  radius: 6,
                  table: "CustomerMeters",
                },
                {
                  label: "Valves",
                  stroke: "#FF8343",
                  fill: "#C75B7A",
                  radius: 4,
                  table: "Valves",
                },
                {
                  label: "Master Meters",
                  stroke: "#FF8343",
                  fill: "#A02334",
                  radius: 8,
                  table: "MasterMeters",
                },
                {
                  label: "Tanks",
                  stroke: "#FF8343",
                  fill: "#1F316F",
                  radius: 10,
                  table: "Tanks",
                },
                {
                  label: "Washouts",
                  stroke: "#FF8343",
                  fill: "#F6E96B",
                  radius: 4,
                  table: "Washouts",
                },
                {
                  label: "Pipeline",
                  stroke: "blue",
                  fill: "blue",
                  radius: 4,
                  table: "WaterPipes",
                },
              ]}
            />
          )}
          {selectedNetwork === "Sewer Network" && (
            <NetworksMaps
              legendItems={[
                {
                  label: "Manholes",
                  stroke: "#77E4C8",
                  fill: "#DC0083",
                  radius: 6,
                  table: "Manholes",
                },
                {
                  label: "Sewer Lines",
                  stroke: "#4535C1",
                  fill: "#4535C1",
                  radius: 4,
                  table: "SewerLines",
                },
              ]}
            />
          )}
          {selectedNetwork === "Project Network" && (
            <NetworksMaps
              legendItems={[
                {
                  label: "Projects (Points)",
                  stroke: "#E68369",
                  fill: "#508D4E",
                  radius: 6,
                  table: "Projects",
                },
                {
                  label: "Projects (Linear)",
                  stroke: "green",
                  fill: "green",
                  radius: 6,
                  table: "ProjectsLines",
                },
              ]}
            />
          )}
        </Box>
      </Card>
    </Container>
  );
}
