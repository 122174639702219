import { useEffect, useState } from "react";
import "../../Styles/billingstats.scss";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import html2canvas from "html2canvas";
import { BsImages, BsFiletypeCsv } from "react-icons/bs";
import CustomLineChart from "../Charts/CustomLineChart";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdAccountBalanceWallet, MdBalance } from "react-icons/md";
import { BsPersonFillCheck } from "react-icons/bs";

import billing_effect from "../../assets/imgs/billing_effect.png";

export default function BillingDashboard(props) {
  const [data, setData] = useState(null);
  const [projects, setProjects] = useState(null);
  const chrt = useRef(null);
  const [showing, setShowing] = useState(true);
  const [aspect, setAspect] = useState(1.5);
  const [aspect1, setAspect1] = useState(1.5);
  const [date, setDate] = useState("");

  const p1ref = useRef();
  const p2ref = useRef();
  const p3ref = useRef();

  const b1ref = useRef();
  const b2ref = useRef();

  const h1ref = useRef();
  const h2ref = useRef();

  useLayoutEffect(() => {
    const { width, height } = p1ref.current.getBoundingClientRect();

    setAspect(width / height);
    setAspect1(width / (height * 0.8));
  }, []);

  const handleDownloadImage = async (printRef) => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  useEffect(() => {
    setShowing(false);
    setTimeout(() => {
      setShowing(true);
    }, 1);
  }, [props.showing]);

  useEffect(() => {
    fetch(`/api/customerbilling/all/stats`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/customerbilling/all/charts`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setProjects(data);
        // let date = new Date(data.updatedAt);
        // let formattedDate = `${date.getHours()}:${date.getMinutes()}  ${date.getDate()}/${
        //   date.getMonth() + 1
        // }/${date.getFullYear()}`;
        // setDate(formattedDate);
      })
      .catch((e) => {});
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const today = new Date();
    setDate(formatDate(today));
  }, []);

  

  return (
    <>
      {showing && (
        <div className="billingstats">
          <div className="billingtop">
            <TopItem
              title="Customers"
              amt={data ? data?.Customers : 0}
              color="#FD8D14"
              color1="#FFE17B"
              icon={BsPersonFillCheck}
            />
            <TopItem
              title="Previous Balance"
              amt={data ? data?.PreviousBalance : 0}
              color="#8062D6"
              color1="#9288F8"
              icon={MdBalance}
            />
            <TopItem
              title="Current Balance"
              amt={data ? data?.CurrentBalance : 0}
              color="#557A46"
              color1="#7A9D54"
              icon={MdAccountBalanceWallet}
            />
            <TopItem
              title="Invoiced Amount"
              amt={data ? data?.InvoiceAmount : 0}
              color="#1c747b"
              color1="#27adb4"
              icon={GiTakeMyMoney}
            />
          </div>
          <div className="section">
            <div className="head">
              <h3>Scheme Billing Summary</h3>
              <p>
                Last update: <span>{date}</span>
              </p>
            </div>
            <div className="container">
              <div ref={p1ref} className="chart">
                <CustomLineChart data={projects?.scOther} aspect={aspect} />
                <div className="save">
                  <BsImages
                    color="blue"
                    onClick={() => {
                      handleDownloadImage(p1ref);
                    }}
                  />
                  <BsFiletypeCsv
                    color="blue"
                    onClick={() => {
                      saveData(projects?.scOther);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="head">
              <h3>Zones Billing Summary</h3>
              <p>
                Last update: <span>{date}</span>
              </p>
            </div>
            <div className="container">
              <div ref={p1ref} className="chart">
                <CustomLineChart data={projects?.znOther} aspect={aspect} />
                <div className="save">
                  <BsImages
                    color="blue"
                    onClick={() => {
                      handleDownloadImage(p1ref);
                    }}
                  />
                  <BsFiletypeCsv
                    color="blue"
                    onClick={() => {
                      saveData(projects?.znOther);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="head">
              <h3>DMA Billing Summary</h3>
              <p>
                Last update: <span>{date}</span>
              </p>
            </div>
            <div className="container">
              <div ref={p1ref} className="chart">
                <CustomLineChart data={projects?.rtOther} aspect={aspect} />
                <div className="save">
                  <BsImages
                    color="blue"
                    onClick={() => {
                      handleDownloadImage(p1ref);
                    }}
                  />
                  <BsFiletypeCsv
                    color="blue"
                    onClick={() => {
                      saveData(projects?.rtOther);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="head">
              <h3>Account Status Billing Summary</h3>
              <p>
                Last update: <span>{date}</span>
              </p>
            </div>
            <div className="container">
              <div ref={p1ref} className="chart">
                <CustomLineChart data={projects?.asOther} aspect={aspect} />
                <div className="save">
                  <BsImages
                    color="blue"
                    onClick={() => {
                      handleDownloadImage(p1ref);
                    }}
                  />
                  <BsFiletypeCsv
                    color="blue"
                    onClick={() => {
                      saveData(projects?.asOther);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="head">
              <h3>Account Type Billing Summary</h3>
              <p>
                Last update: <span>{date}</span>
              </p>
            </div>
            <div className="container">
              <div ref={p1ref} className="chart">
                <CustomLineChart data={projects?.atOther} aspect={aspect} />
                <div className="save">
                  <BsImages
                    color="blue"
                    onClick={() => {
                      handleDownloadImage(p1ref);
                    }}
                  />
                  <BsFiletypeCsv
                    color="blue"
                    onClick={() => {
                      saveData(projects?.atOther);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const TopItem = (props) => {
  const [value, setValue] = useState(props.amt);
  const [animate, setAnimate] = useState(false);
  const vl = useRef();

  useEffect(() => {
    animateValue(vl.current, 0, props.amt, 1000);
  }, [props.amt]);

  function animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = withCommas(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(45deg, ${props.color}, ${props.color1})`,
      }}
      className="tp_item"
    >
      <img src={billing_effect} alt="" />
      <props.icon size={44} className="icon" />
      <h1 ref={vl}></h1>
      <p>{props.title}</p>
    </div>
  );
};
