import React, { useState } from "react";
import { Box, ButtonGroup, Button, Container, Card } from "@mui/material";
import BillingDashboard from "../components/Billing/BillingDashboard";
import BillingData from "../components/Billing/BillingData";

const selectList = ["Dashboard", "Data"];

export default function Billing() {
  const [selectedNetwork, setSelectedNetwork] = useState("Dashboard");

  const handleNetworkChange = (network) => {
    setSelectedNetwork(network);
  };

  return (
    <Container sx={{ marginTop: 8 }} disableGutters>
      <Card
        sx={{
          p: 2,
          borderRadius: 5,
          boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          minHeight: "90vh",
          display: "grid",
          gridTemplateRows: "auto auto 1fr",
        }}
      >
        <ButtonGroup
          variant="contained"
          sx={{
            boxShadow: "none",
          }}
          aria-label="network toggle buttons"
        >
          {selectList.map((item, i) => {
            return (
              <Button
                key={i}
                onClick={() => handleNetworkChange(item)}
                variant={selectedNetwork === item ? "contained" : "outlined"}
                sx={{ textTransform: "capitalize" }}
              >
                {item}
              </Button>
            );
          })}
        </ButtonGroup>

        <Box>{selectedNetwork == "Dashboard" && <BillingDashboard />}</Box>
        <Box>{selectedNetwork == "Data" && <BillingData />}</Box>
      </Card>
    </Container>
  );
}
