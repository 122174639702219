import React, { useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Container,
  Card,
} from "@mui/material";
import Data from "../components/data/Data";

const selectList = [
  {
    label: "Customers",
    url: "customers",
  },
  {
    label: "Tanks",
    url: "tanks",
  },
  {
    label: "Valves",
    url: "valves",
  },
  {
    label: "Washouts",
    url: "washouts",
  },
  {
    label: "Master Meters",
    url: "mastermeters",
  },
  {
    label: "Manholes",
    url: "manholes",
  },
  {
    label: "Sewer Lines",
    url: "sewerlines",
  },
  {
    label: "Water Pipes",
    url: "waterpipes",
  },
  {
    label: "Projects",
    url: "projects",
  },
  {
    label: "Projects Linear",
    url: "projectslines",
  },
];

export default function AllData() {
  const [selectedNetwork, setSelectedNetwork] = useState("Customers");

  const handleNetworkChange = (network) => {
    setSelectedNetwork(network);
  };

  return (
    <Container sx={{ marginTop: 8 }} disableGutters>
      <Card
        sx={{
          p: 2,
          borderRadius: 5,
          boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          minHeight: "90vh",
          display: "grid",
          gridTemplateRows: "auto auto 1fr",
        }}
      >
        <ButtonGroup
          variant="contained"
          sx={{
            boxShadow: "none",
          }}
          aria-label="network toggle buttons"
        >
          {selectList.map((item, i) => {
            return (
              <Button
                key={i}
                onClick={() => handleNetworkChange(item.label)}
                variant={
                  selectedNetwork === item.label ? "contained" : "outlined"
                }
                sx={{ textTransform: "capitalize" }}
              >
                {item.label}
              </Button>
            );
          })}
        </ButtonGroup>

        <Box>
          {selectList.map((item, i) => {
            if (item.label == selectedNetwork) {
              return <Data url={item.url} />;
            }
          })}
        </Box>
      </Card>
    </Container>
  );
}
